import { SelectOption } from './AutomationRequirements/AutocompleteComponents'

export type BaserowSelectOption = {
  id: number
  value: string
  color: string
}

export enum FindSelectorType {
  LAST_MODIFIED = 'LAST_MODIFIED',
  LAST_CREATED = 'LAST_CREATED',
  FIRST_MODIFIED = 'FIRST_MODIFIED',
  FIRST_CREATED = 'FIRST_CREATED',
  ALL_RECORDS = 'ALL_RECORDS',
}

export type BaserowField = {
  id: number
  name: string
  type: string
  select_options?: BaserowSelectOption[]
  formula_type?: string
  link_row_table_id?: number
  metadata?: Record<string, any>
  date_force_timezone?: string
  date_include_time?: boolean
  array_formula_type?: string
  formula_type_parameters?: Record<string, any>
}

export type BaserowCollaborator = {
  id: number
  name: string
  email: string
  user_id: string
}

export type RestrictToViewOption = {
  slug: string
  label: string
  value: string
}

export type FieldType = {
  value: string
  label: string
}

export type InputType = {
  value: string
  label: string
  params: Record<string, any>
}

export type FilterType = {
  value: string
  label: string
  params: Record<string, any>
}

export type EnrichedActionItemType = {
  fieldName: string
  fieldType: string
  fieldId: string
  inputType: InputType
}

export enum AutomationsUserNotificationMatchOnType {
  MEMBERSHIP_IDS = 'MEMBERSHIP_IDS',
  WORKFLOW_FIELD_VALUE = 'WORKFLOW_FIELD_VALUE',
}

export enum AutomationsUserNotificationMatchType {
  'EMAIL' = 'EMAIL',
  'FULL_NAME' = 'FULL_NAME',
  'MEMBERSHIP_ID' = 'MEMBERSHIP_ID',
  'BASEROW_COLLABORATOR' = 'BASEROW_COLLABORATOR',
}

export type AutomationsUserNotificationType = {
  matchValues: SelectOption[]
  matchOn: AutomationsUserNotificationMatchOnType
  matchType: AutomationsUserNotificationMatchType
}

export type AutomationUserNotificationDataType = {
  matchingData: AutomationsUserNotificationType
  notificationMessage: string
  notificationTitle: string
}

export type EnrichedActionType = {
  order: number
  outputTableId?: string
  action: ActionExecutionType
  data: EnrichedActionItemType[]
  notifyUserData: AutomationUserNotificationDataType[]
}

export type FilterParamType = {
  type: 'input' | 'options' | 'list'
  label: string
  value: any // Can be string, number, array, etc., depending on the context
  dataType?: 'list' | 'number' | 'date' | 'integer'
  isConditional?: boolean
  dependency?: DependencyType
}

export type DependencyType = {
  reference: number // Refers to the key in the params object
  conditions: {
    [key: string]: {
      dataType: 'date' | 'integer' | 'list' | 'number'
    }
  }
}

export type RequirementType = {
  id: string
  fieldName: string
  fieldType: string
  fieldId: string
  filter: {
    value: string
    label: string
    params: Record<string, FilterParamType>
  }
}

export type RequirementGroupType = {
  id: string
  rule: string
  requirements: (RequirementType | RequirementGroupType)[]
}

export type FieldsToWatchType = {
  id: number
  name: string
  type: string
}
export enum ActionExecutionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPDATE_FROM_ADVANCED_LOGIC = 'UPDATE_FROM_ADVANCED_LOGIC',
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  SEND_EMAIL = 'SEND_EMAIL',
}
export enum AdvancedLogicType {
  FIND = 'FIND',
}

export type LinkedFieldForFindType = {
  value: number
  label: string
  linkedToTable: number
  type?: string
}

// Used after a trigger has happened. The trigger is anything that happens in the table that the automation is watching
// This advanced logic is baserow specific
// So, if a trigger happens and there is advanced logic
export type AdvancedLogicObject = {
  useAdvancedLogic: boolean // True if user wants advanced logic
  advancedLogicType: AdvancedLogicType // Define the advanced logic type. For now, this is FIND only
  // If the user selects FIND (currently the only option)
  // There are two types of find:
  // - We find records linked to the TRIGGER RECORD; or
  // - We find records from a given base/table and potentially view
  FIND: {
    TYPE: AdvancedLogicFindType // Using the enum below defines if the find is based on linked records or a general find of unrelated objects
    // This defines the properties of the link. The link has a table that the record links to, as well as the value (the field id) and the label (the field name)
    linkedFieldData?: linkedFieldForFindType
    // This defines the properties of the general find. It contains the base Id, table Id and view Id to search for records.
    // Note that only up to 100 records are returned, so we need to alert the user of this restriction. Ideally, we can provide filters on the query to help
    // reduce the number of records returned, but for now we don't have that luxury.
    findRecordData?: FindRecordsForFindType

    // Now, based on the FOUND records, we can filter further. The filters allow the user to refine the records found to be filtered by field values. For example,
    // Finding all records with a status of ACTIVE
    filters?: RequirementGroupType[]

    // A selector is used to select a SPECIFIC record based on the enum value used. The record selected is then used to apply actions
    // The selector can be used IN CONJUNCTION with filters. For example, a selector of LAST MODIFIED used in conjuntion with a filter of Status==="Active"
    // Means that it will select the last modified record that is active
    selector?: FindSelectorType
    // Now because baserow doesn't allow us to get metadata about a record in the same way that airtable does, we need to provide a way to get the data.
    // So a simple solution is to make the user provide the id of a field that contains the metadata (modified or created time) so we can then perform the operation
    selectorField?: {
      value: string
      label: string
    }
  }
}

export enum advancedLogicType {
  FIND = 'FIND',
}

export enum AdvancedLogicFindType {
  LINKED_FIELD = 'LINKED_FIELD',
  FIND_RECORDS = 'FIND_RECORDS',
}

export type linkedFieldForFindType = {
  value: number
  label: string
  linkedToTable: number
}

export type FindRecordsForFindType = {
  baseId: string
  tableId: string
  viewId?: string
}

export enum TemporalTriggerType {
  'MINUTE' = 'MINUTE',
  'HOUR' = 'HOUR',
  'DAY' = 'DAY',
  'WEEK' = 'WEEK',
  'MONTH' = 'MONTH',
  'YEAR' = 'YEAR',
  'ONCE' = 'ONCE',
}

export type SelectedLinkFieldRefineValueOption = {
  value: FindSelectorType
  label: string
}

export type MatchCriteriaType = {
  RECORD: {
    useViewsAsFilters: boolean
    selectedView: {
      value: string
      label: string
      id: number
    }
    requirements: RequirementGroupType[]
  }
  FIELD: {
    fieldsToWatch: FieldsToWatchType[]
    restrictToView: FieldType
  }
  // Use for triggers relating to periodic time events
  // Cases are:
  // - Every X minutes
  // - Every X hours
  // - Every X days, at time Y
  // - Every X weeks, at time Y, on day (mon, tue...) Z
  // - Every X months, at time Y, on day (1,2,3....) Z
  // - Every X years, at time Y, on day (1,2,3....) Z, in month (jan, feb...) W
  // - Once at a specific time and date
  TEMPORAL?: {
    temporalType: TemporalTriggerType // The type of temporal trigger as defined in the enum above
    interval: number // The interval at which the trigger should happen. For example "every _5_ minutes"
    timeOfDay?: string // If the trigger is day or larger, the time of day the trigger should happen
    dayOfWeek: number[] // If the trigger is weekly, the day(s) of the week the trigger should happen. Needs to also have a timeOfDay
    dayOfMonth: number[] // If the trigger is monthly or yearly, the day(s) of the month the trigger should happen. Needs to also have a timeOfDay
    monthOfYear: number[] // If the trigger is yearly, the month of the year the trigger should happen. Needs to also have a dayOfMonth and timeOfDay
    startDate?: string // The start date of the trigger. Must be in the future when created by at least 30 minutes (to ensure queuer doesn't miss it). If the temporalType is ONCE, this is the date the trigger should happen
  }
  advancedLogic: AdvancedLogicObject
}

export type TruncatedType = {
  truncatedStringValue: string
  type: string
}

export enum AutomationTriggerType {
  RECORD = 'RECORD',
  FIELD = 'FIELD',
  TEMPORAL = 'TEMPORAL',
}

export type BaserowObject = {
  inUseElsewhere?: boolean
  value: number
  label: string
  group?: string
  groupId?: number
}

export const setStringToTitleCase = (str: string) => {
  if (str) {
    const strings = str?.split('_')
    const newStrings = strings?.map((string) => {
      return string.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase()
    })
    return newStrings?.join(' ')
  }
  return str
}

export const selectColorConverter = (color: string) => {
  const colorTranslator = {
    'light-blue': '#f0f4fc',
    'light-green': '#ecfcf1',
    'light-cyan': '#cff5fa',
    'light-yellow': '#fffbf0',
    'light-orange': '#fffbf0',
    'light-red': '#fff2f0',
    'light-brown': '#f5e6dc',
    'light-purple': '#f9f1fd',
    'light-pink': '#f7e1f2',
    'light-gray': '#f5f5f5',
    blue: '#dae4fd',
    green: '#d0f6dc',
    cyan: '#a0ebf5',
    yellow: '#ffe9b4',
    orange: '#fff4da',
    red: '#ffdeda',
    brown: '#f5ceb0',
    purple: '#dfb9f7',
    pink: '#f7b2e7',
    gray: '#d7d8d9',
    'dark-blue': '#acc8f8',
    'dark-green': '#a0eeba',
    'dark-cyan': '#70e0ef',
    'dark-yellow': '#ffdd8f',
    'dark-orange': '#ffe9b4',
    'dark-red': '#ffbdb4',
    'dark-brown': '#f5c098',
    'dark-purple': '#cf96f2',
    'dark-pink': '#f285d9',
    'dark-gray': '#b5b5b7',
    'darker-blue': '#689ef1',
    'darker-green': '#41dd75',
    'darker-cyan': '#41d6ea',
    'darker-yellow': '#ffd269',
    'darker-orange': '#ffd269',
    'darker-red': '#ff7b69',
    'darker-brown': '#f5a96e',
    'darker-purple': '#bf73ee',
    'darker-pink': '#ff6dde',
    'darker-gray': '#b5b5b7',
  }

  return color in colorTranslator ? colorTranslator[color] : color
}

export const selectedLinkFieldRefineValueOptions: SelectedLinkFieldRefineValueOption[] =
  [
    {
      value: FindSelectorType.ALL_RECORDS,
      label: 'All Records',
    },
    {
      value: FindSelectorType.LAST_MODIFIED,
      label: 'Last Modified',
    },
    {
      value: FindSelectorType.LAST_CREATED,
      label: 'Last Created',
    },
    {
      value: FindSelectorType.FIRST_CREATED,
      label: 'First Created',
    },
    {
      value: FindSelectorType.FIRST_MODIFIED,
      label: 'First Modified',
    },
  ]

export type SelectOptionWithFieldData = {
  value: string
  label: string
  fieldData: BaserowField
}
